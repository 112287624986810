import { Component } from '@angular/core';
import { retryWhen } from 'rxjs/operators';
@Component({
selector: 'app-ValidationService',
templateUrl: '../app.component.html',
styleUrls: ['../app.component.scss']
})

export class ValidationService {

 public static Pattern = {
    //emailCC: "^(\s*,?\s*[0-9a-za-z]([-.\w]*[0-9a-za-z])*@([0-9a-za-z][-\w]*[0-9a-za-z]\.)+[a-za-z]{2,9})+\s*$",
    emailCC: "^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$",
    //emailCC: "/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g",
    email: "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
    PhoneNumber:"^[0-9]{12}$",
    Fax:"^[0-9]{12}$",
    Postal:"^[0-9]{6}$",
    Date:"^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$"
   }
 public static TextFormat = {
    // Name: "^[A-Z]{1}[a-z]+(?:[ ]+[a-zA-Z][a-z]+)*$",
    Name: "^[A-Z]{1}([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$",
    Address: "^[A-Z]{1}[a-z]+(?:[ ]+[a-zA-Z][a-z]+)*$",
   }
   public static ToUpper(string){
   return string.toUpperCase( )
   }
}












