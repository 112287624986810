import { Injectable, Injector  } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

//import { Observable } from 'rxjs/Observable';
import { Observable,throwError} from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/throw';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router'
import { ConstantComponent } from '../../../src/app/constant.component';
import { AuthGuard } from '../shared/guard/auth.guard';




@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {   
    private authService: AuthGuard;
    constructor(private router: Router, private injector: Injector){        
    }   
    headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
    });
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService = this.injector.get(AuthGuard); // get it here within intercept        
        //console.log("intercepted request ... ");
        //debugger;
        // Clone the request to add the new header.
        const authReq = req.clone({ headers: req.headers.set("Authorization", 'Bearer ' + localStorage.getItem("token")) });
        //console.log("token: "+ localStorage.getItem("token"));

        //send the newly created request
        return next.handle(authReq)
            .pipe(catchError(err => {
                // onError
                //debugger;
                console.log(err);
                if (err instanceof HttpErrorResponse) {
                    console.log(err.status);
                    console.log(err.statusText);
                    if (err.status === 401) {
                        //window.location.href = "/login";                        
                        //this.onInterceptorLoggedout();         
                        if(localStorage.getItem("isLoggedout") == "false")                                       
                        {
                        this.authService.onTokenLoggedout();
                        this.router.navigate(['/login']);
                        }
                    }
                }
                return throwError(err);//Observable.throw(err);
            })) as any;
    }

    onInterceptorLoggedout() {        
        //const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        //let headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' });
        //let options = new RequestOptions({ headers: headers });
        
        //this.http1.put(ConstantComponent.url + "/UserAPI/LogOut/" + parseInt(localStorage.getItem("userIdLogged")), options).subscribe(data => {                      
        //console.log(data)
        //if (data == true) {
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('userAllRights');
        localStorage.removeItem('SuperAdminRights');
        localStorage.removeItem('subscriberIdLogged');
        localStorage.removeItem('usernameLogged');
        localStorage.removeItem('subscriberNameLogged');
        localStorage.removeItem('userIdLogged');
        localStorage.removeItem('financialYearIdLogged');
        localStorage.removeItem('userRights');
        localStorage.removeItem('MaintainerRights');
        localStorage.removeItem('tyreDashboardStatus');
        localStorage.removeItem('companyName');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExp');
        localStorage.removeItem('SessionValue');
    //    }         
    //},
    //error => {
    //   // bootbox.alert("Something went wrong! Please try again")
    //})      

    }
}




//import { Headers, Http, BaseRequestOptions } from '@angular/http';
////import { TOKEN_NAME } from './auth.service';

//const AUTH_HEADER_KEY = 'Authorization';
//const AUTH_PREFIX = 'Bearer';

//export class AuthRequestOptions extends BaseRequestOptions {
  
//  constructor() {
//    super();
//    debugger;
//    const token = localStorage.getItem('token');
//    if(token) {
//      this.headers.append(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`);
//    }
//  }

//}


//import { Injectable } from '@angular/core';
//import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
//import { Observable } from 'rxjs';

//@Injectable()
//export class httpInterceptor implements HttpInterceptor {    
//    //intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {       
//    //    debugger; 
//    intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {        
//        debugger; 
//    // add authorization header to request
    
//    //Get Token data from local storage
//    let tokenInfo = localStorage.getItem('token');//JSON.parse(localStorage.getItem('token'));
//    debugger; 
    
//    //if (tokenInfo && tokenInfo.token) {
//        request = request.clone({
//    setHeaders: {
//    Authorization: `Bearer ${tokenInfo}`,
//    //Authorization: 'Bearer ${tokenInfo}',
//    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
//    }
//    });
//    //}
    
//    return newRequest.handle(request);
//    }
//   }