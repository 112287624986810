import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public progressStatus: BehaviorSubject<any> = new BehaviorSubject<any>({});

    display(value: boolean) {
        this.status.next(value);
    }
    progressBar(session, percentage)
    {
        this.progressStatus.next({session: session, percentage:percentage})
    }
}