import { Component } from '@angular/core';

@Component({
    selector: 'app-Constant',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class ConstantComponent {        
    public static url:string;
    public static port:string;
    public static reportUrl:string;
    //public static SSRSReportUrl:string="http://ssrsstaging.efleetsystems.com";
    public static SSRSReportUrl:string;
          
    public static userAllRights = JSON.parse(localStorage.getItem('userAllRights'))
    public static userRights: any = localStorage.getItem('userRights');
    public static subscriberIdLogged: Number = Number(localStorage.getItem('subscriberIdLogged'));
    public static subscriberNameLogged: String = localStorage.getItem('subscriberNameLogged');
    public static companyName: String = localStorage.getItem('companyName');

    public static userIdLogged = localStorage.getItem('userIdLogged');
    public static usernameLogged: String = localStorage.getItem('usernameLogged');
    public static userEmailId: String = localStorage.getItem('userEmailId');
    public static financialYearIdLogged = Number(localStorage.getItem('financialYearIdLogged'));
    public static superAdminRights: any = localStorage.getItem('SuperAdminRights');
    public static maintainerRights: any = localStorage.getItem('MaintainerRights');
    public static userAccessRights = JSON.parse(localStorage.getItem('userAccessRights'));// stored user acccess rights
    public static IsSubscriber: any = localStorage.getItem('IsLoginUserSubscriber'); // stored to check is login user subscriber or not
    //Store User Session Mapping Details
    public static UserSessionId: Number = Number(localStorage.getItem('UserSessionId'));
    public static IpAddress: String = localStorage.getItem('IpAddress') == "" ? 'NA' : localStorage.getItem('IpAddress');
    public static BrowserName: String = localStorage.getItem('BrowserName');
    public static BrowserVersion: String = localStorage.getItem('BrowserVersion');
    //end user session mapping details
    public static DynamicMenuList:any=JSON.parse(localStorage.getItem('DynamicMenuList'));
    public static UserRoleId:Number=Number(localStorage.getItem('UserRoleId'));// stored user role id
    public static IsTrolleyApplicable:boolean =localStorage.getItem('IsTrolleyApplicable')=="true"?true:false;  
    public static IsCeatCustomer:boolean =localStorage.getItem('IsCeatCustomer')=="true"?true:false;  
    public static UserRoleGroupId:Number=Number(localStorage.getItem('UserRoleGroupId'));// stored user role id
    public static ITyreRequired:boolean =localStorage.getItem('ITyreRequired')=="true"?true:false;  
    public static TPMSFittedRequired:boolean =localStorage.getItem('TPMSFittedRequired')=="true"?true:false;  
    public static IsSmallMediumFleet:boolean =localStorage.getItem('IsSmallMediumFleet')=="true"?true:false;      
    public static BuConfig = JSON.parse(localStorage.getItem('BuConfig'));// stored user Bu Configuration Details
    public static BUMasterId:Number=Number(localStorage.getItem('BUMasterId'));// stored BU Master Id
    public static BusinessTypeId:Number=Number(localStorage.getItem('BusinessTypeId'));// stored BU Master Id
}
