import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

//import { HeaderComponent } from '../components/header/header.component';
//import * as jwt_decode from 'jwt-decode';
//import { RequestOptions } from '@angular/http';
import { ConstantComponent } from 'app/constant.component';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import {HttpClient} from '@angular/common/http';

export const TOKEN_NAME: string = 'token';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private http1: HttpClient) { }

    canActivate() {
        //if (localStorage.getItem('isLoggedin')) {
            if (!this.isTokenExpired()) {
            return true;
        }        
        this.onTokenLoggedout();
        this.router.navigate(['/login']);        
        return false;
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
      }
    
      setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
      }
    
      getTokenExpirationDate(token: string): Date {        
        const decoded = localStorage.getItem('tokenExp');//jwt_decode(token);    
    
        if (decoded === undefined) return null;
        
        let newDate = new Date(decoded);
        //const date = new Date(0); 
        //date.setUTCSeconds(parseInt(decoded));
        return newDate;//date;
      }
    
      isTokenExpired(token?: string): boolean {
        if(!token) token = this.getToken();
        if(!token) return true;
    
        const date = this.getTokenExpirationDate(token);
        if(date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
      }

      onTokenLoggedout() {                
        //localStorage.removeItem('isLoggedout');
        localStorage.setItem('isLoggedout', 'true');
        let headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' });
        let options = new RequestOptions({ headers: headers });

        //Add UserSessionMapping Details in Object
        let UserSessionDetails:any = {};
        UserSessionDetails.UserId = ConstantComponent.userIdLogged;
        UserSessionDetails.UserSessionId = ConstantComponent.UserSessionId;
        UserSessionDetails.FormId = 0;
        UserSessionDetails.IpAddress = ConstantComponent.IpAddress;
        UserSessionDetails.BrowserName = ConstantComponent.BrowserName;
        UserSessionDetails.BrowserVersion = ConstantComponent.BrowserVersion;    
        
        this.http1.put(ConstantComponent.url + "/UserAPI/LogOut/" + parseInt(localStorage.getItem("userIdLogged")), UserSessionDetails).subscribe((data:any) => {                      
        console.log(data)
        if (data.isSuccess == true) {
          localStorage.clear();
          //     localStorage.removeItem('isLoggedin');
          // localStorage.removeItem('userAllRights');
          // localStorage.removeItem('SuperAdminRights');
          // localStorage.removeItem('subscriberIdLogged');
          // localStorage.removeItem('usernameLogged');
          // localStorage.removeItem('subscriberNameLogged');
          // localStorage.removeItem('userIdLogged');
          // localStorage.removeItem('financialYearIdLogged');
          // localStorage.removeItem('userRights');
          // localStorage.removeItem('MaintainerRights');
          // localStorage.removeItem('tyreDashboardStatus');
          // localStorage.removeItem('companyName');
          // localStorage.removeItem('token');
          // localStorage.removeItem('tokenExp');    
          // //Remove User Session Mapping Details from Local Storage
          // localStorage.removeItem('UserSessionId');
          // localStorage.removeItem('IpAddress');
          // localStorage.removeItem('BrowserName');
          // localStorage.removeItem('BrowserVersion');   
  
        }         
    },
    error => {
       // bootbox.alert("Something went wrong! Please try again")
    })      


    }
}
