
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConstantComponent } from '../../../constant.component';
import { } from 'jquery';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppError } from '../../../GenericService/app-error';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { DynamicMenuService } from './DynamicMenu.service';
import { LoaderService } from '../../../GenericService/loader.service';

@Component({
    selector: 'app-DynamicMenu',
    templateUrl: './DynamicMenu.component.html',
    // styleUrls: ['./DynamicMenu.component.scss'],
    providers: [DynamicMenuService]
})
export class DynamicMenuComponent implements OnInit {
    constructor(public router: Router, private route: ActivatedRoute, private service: DynamicMenuService, private loaderService: LoaderService) { }
    ParentMenuList: any = [];
    ModuleList: any = [];
    DynamicMenuList: any = [];
    DynamicClass: any;
    Type: any;   
    BuMasterId:any;
   
    IsCeatCustomer = localStorage.getItem('IsCeatCustomer') == 'true' ? true : false;
   
    ngOnInit() {

        //this.addBgClass()
        this.os_init_sub_menus()
        this.os_init_mobile_menus()
        this.GetDynamicMenuList('');
        
    this.BuMasterId = localStorage.getItem('BUMasterId') ;

       
    }


    GetDynamicMenuList = function (vType) {        
        if (vType == 'Home') {
            localStorage.removeItem('vehicleDashboardStatus')
            localStorage.removeItem('tyreDashboardStatus')
            localStorage.removeItem('inventoryDashboardStatus')
            localStorage.removeItem('ModuleIdClick')
            this.router.navigate(['./dashboard']);
        }
        let mModuleId = localStorage.getItem('ModuleIdClick');   
        this.DynamicMenuList=[];
        this.ParentMenuList=[];
        this.ModuleList=[];
        this.DynamicMenuList=ConstantComponent.DynamicMenuList;
        if(mModuleId==null){          
            for(var i=0;i<this.DynamicMenuList.length;i++){
                if(this.DynamicMenuList[i].id==4){
                    this.ParentMenuList=this.DynamicMenuList[i].dynamicMenuDatas;
                }
                else if(this.DynamicMenuList[i].id==1){
                    this.ParentMenuList=this.DynamicMenuList[i].dynamicMenuDatas                    
                }
                else{
                    this.ModuleList.push(this.DynamicMenuList[i]);
                }
               
            }
        }
        else{
            for(var i=0;i<this.DynamicMenuList.length;i++){
            if (this.DynamicMenuList[i].id == mModuleId) {
                            this.ParentMenuList = this.DynamicMenuList[i].dynamicMenuDatas;
                        }
                        if(this.DynamicMenuList[i].id!=mModuleId){
                            this.ModuleList.push(this.DynamicMenuList[i]);
                        }
                    }
        }
        // for Query Params
        if (this.ParentMenuList.length > 0) {
            for (var i = 0; i < this.ParentMenuList.length; i++) {
                if (this.ParentMenuList[i].dynamicMenuDatas != null) {
                    for (var j = 0; j < this.ParentMenuList[i].dynamicMenuDatas.length; j++) {
                        var mMenuUrl;
                        var mQueryParams;
                        mMenuUrl = this.ParentMenuList[i].dynamicMenuDatas[j].menuUrl.split('?')
                        this.ParentMenuList[i].dynamicMenuDatas[j].menuUrl = mMenuUrl[0];
                        mQueryParams = mMenuUrl[1];
                        if (mQueryParams != null) {
                            var mKeyValue = [];
                            mKeyValue = mQueryParams.split("=");
                            this.ParentMenuList[i].dynamicMenuDatas[j].queryValueParams = mKeyValue[1];
                        }
                    }
                }
            }
        }
    
    }
    addBgClass = function () {
        $('body').removeClass('bg-color-class');
    }

    os_init_sub_menus = function () {

        // INIT MENU TO ACTIVATE ON HOVER
        var menu_timer;
        $('.menu-activated-on-hover').on('mouseenter', 'ul.main-menu > li.has-sub-menu', function () {
            var $elem = $(this);
            clearTimeout(menu_timer);
            $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
            $elem.addClass('active');
        });

        $('.menu-activated-on-hover').on('mouseleave', 'ul.main-menu > li.has-sub-menu', function () {
            var $elem = $(this);
            menu_timer = setTimeout(function () {
                $elem.removeClass('active').closest('ul').removeClass('has-active');
            }, 30);
        });


        // INIT MENU TO ACTIVATE ON CLICK
        $('.menu-activated-on-click').on('click', 'li.has-sub-menu > a', function (event) {            
            var $elem = $(this).closest('li');
            if ($elem.hasClass('active')) {
                $elem.removeClass('active');
            } else {
                $elem.closest('ul').find('li.active').removeClass('active');
                $elem.addClass('active');
            }
            return false;
        });
    }
    // INIT MOBILE MENU TRIGGER BUTTON
    os_init_mobile_menus = function () {                 
        $('.mobile-menu-trigger').on('click', function () {            
            $('.menu-and-user').slideToggle(200, 'swing');            
            return false;
        });
    }
    mouseEnter(div: string, index, vCol) {
        var mTranslate=index-1;
        if (vCol == "Col1") {
            $('#divSubMenu1_' + index).show();    
            if(document.getElementById("divSubMenu1_"+index) != null)        
            {
                document.getElementById("divSubMenu1_"+index).style.transform="translateY("+mTranslate+"00px) scale(1);"          
            }            
        }
        if (vCol == "Col2") {
            $('#divSubMenu2_' + index).show();          
            if(document.getElementById("divSubMenu2_"+index) != null)
            {
                document.getElementById("divSubMenu2_"+index).style.transform="translateY(100px) scale(1)"
            }            
        }
        if (vCol == "Col3") {
            $('#divSubMenu3_' + index).show();
            if(document.getElementById("divSubMenu3_"+index) != null)
            {
                //document.getElementById("divSubMenu3_"+index).style.transform="translateY("+mTranslate+"00px) scale(1)"
                document.getElementById("divSubMenu3_"+index).style.transform="translateY(20px) scale(1)"
            }            
        }
        if (vCol == "Col4") {
            $('#divSubMenu4_' + index).show();
            if(document.getElementById("divSubMenu4_"+index) != null)
            {
                document.getElementById("divSubMenu4_"+index).style.transform="translateY("+mTranslate+"00px) scale(1)"
            }            
        }
    }

    mouseLeave(div: string, index,vCol) {
        if (vCol == "Col1") {
            $('#divSubMenu1_' + index).hide();
        }
        if (vCol == "Col2") {
            $('#divSubMenu2_' + index).hide();
        }
        if (vCol == "Col3") {
            $('#divSubMenu3_' + index).hide();
        }
        if (vCol == "Col4") {
            $('#divSubMenu4_' + index).hide();
        }

        
    }
    showHideDashboard = function (eve) {        
        this.ParentMenuList = [];
        this.ModuleList = [];
        localStorage.removeItem('vehicleDashboardStatus')
        localStorage.removeItem('tyreDashboardStatus')
        localStorage.removeItem('inventoryDashboardStatus')        
        localStorage.removeItem('ModuleIdClick');
        localStorage.setItem('ModuleIdClick', eve);

        for (var i = 0; i < this.DynamicMenuList.length; i++) {
            if (this.DynamicMenuList[i].id == eve) {
                this.ParentMenuList = this.DynamicMenuList[i].dynamicMenuDatas;
            }
            if (this.DynamicMenuList[i].id != eve) {
                this.ModuleList.push(this.DynamicMenuList[i]);

                for (var j = 0; j < this.ModuleList.length; j++) {
                    this.ModuleList[j].DynamicClass = "";
                    this.ModuleList[j].DynamicClass = "fa fa-fast-backward";
                }
            }
        }
        if (eve == 2) {
            localStorage.setItem('tyreDashboardStatus', 'true');    
            this.router.navigate(['./dashboard'], { queryParams: { type: "Tyre" } });
        }
        else if (eve == 3) {
            localStorage.setItem('vehicleDashboardStatus', 'true');    
            this.router.navigate(['./dashboard'], { queryParams: { type: "Vehicle" } });
        }
        else {
            localStorage.setItem('inventoryDashboardStatus', 'true');   
            this.router.navigate(['./dashboard'], { queryParams: { type: "Operation" } });
        }         
    }


}
