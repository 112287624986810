import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { } from 'jquery';
declare var $;
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { debounceTime,distinctUntilChanged,map,filter } from 'rxjs/operators';

import { ConstantComponent } from '../../../constant.component';
import { headerService } from './header.service';
import { AppError } from 'app/GenericService/app-error';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [headerService]
})

export class HeaderComponent implements OnInit {
usernameLogged:string
UserRoleName:string
subscriberNameLogged : string
SAMenu:boolean = true
mainterMenu:boolean = true
companyName:string
maintenanceData:any = []
maintenance:any = {}   
errorText:any 
totalFOE:any = []
totalFleet:any = []
IsSuperAdmin:boolean = false;
IsFOEVisible:boolean=true;
IsFleetScoreVisible:boolean=true;
Base64Data:any;
ImageData:any;
imagePath:any;
ImageBase64:any;
constructor(private service: headerService,public router: Router,private modalService: NgbModal,private http: Http,private sanitizer: DomSanitizer) {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && window.innerWidth <= 992) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        
        this.os_dropdown()
        if(ConstantComponent.subscriberIdLogged==0){
            this.IsSuperAdmin=true;
        }
        else{
          this.IsSuperAdmin=false;
          this.GetUserProfileImage();
        }             
        if(ConstantComponent.BuConfig!=null){
            let buConfigFoe = ConstantComponent.BuConfig.filter(x=>x.buConfigId==2)
            if(buConfigFoe!=null){
                if(buConfigFoe.length>0){
                    if(buConfigFoe[0].configValue.toLowerCase()=="true"){
                        this.IsFOEVisible = false;
                    }
                }
               
            }   
            let buConfigFleet = ConstantComponent.BuConfig.filter(x=>x.buConfigId==3)
            if(buConfigFleet!=null){
                if(buConfigFleet.length>0){
                    if(buConfigFleet[0].configValue.toLowerCase()=="true"){
                        this.IsFleetScoreVisible = false;
                    }
                }           
            }   
        }
       
        this.usernameLogged = localStorage.getItem('usernameLogged')
        this.UserRoleName= localStorage.getItem('UserRoleName')
        this.companyName=localStorage.getItem('companyName')
        this.subscriberNameLogged = localStorage.getItem('subscriberNameLogged')
        if (localStorage.getItem('SuperAdminRights')) {
            this.SAMenu = false
            this.mainterMenu = true
        } else if (localStorage.getItem('MaintainerRights')) {
            this.mainterMenu = false
            this.SAMenu = true
        }  else {
            this.SAMenu = true
            this.mainterMenu = true
        }
        this.service.get(ConstantComponent.url + "/MaintenanceAPI/GetDetailsById/?UserId="+ ConstantComponent.userIdLogged +"&UserSessionId="+ConstantComponent.UserSessionId+"&FormId=0&IpAddress="+ConstantComponent.IpAddress+"&BrowserName="+ConstantComponent.BrowserName+"&BrowserVersion="+ConstantComponent.BrowserVersion).subscribe(data => {
            console.log(data)
            this.maintenance=data
            if (data != null) {
                var current = new Date();
                var nums = this.maintenance.endTime.split(':');
                var currentTime = (current.getHours() * 60 * 60 * 1000) + (current.getMinutes() * 60 * 1000)
                var endTime = (parseInt(nums[0]) * 60 * 60 * 1000) + (parseInt(nums[1]) * 60 * 1000);
                var currentDate = this.setDate("");
                var maintenanceDate = this.setDate(this.maintenance.date)
                if(currentDate == maintenanceDate) {
                    if(currentTime > endTime) {
                        this.service.get(ConstantComponent.url + '/MaintenanceAPI/StopService').subscribe(data => {
                                console.log(data)
                                this.maintenanceData = []
                            },
                            error => {
                            // bootbox.alert("Something went wrong! Please try again")
                            }
                        );
                    } else {
                        this.maintenanceData.push(data)
                        this.maintenanceData[0].startTime = this.convertTime(this.maintenance.startTime)
                        this.maintenanceData[0].endTime = this.convertTime(this.maintenance.endTime)
                    }
                }
            } 
            console.log(this.maintenanceData)
        },
        error => {
           // bootbox.alert("Something went wrong! Please try again")
        })

        //Called api to get total FOE score details
        this.service.get(ConstantComponent.url + "/KpiMasterAPI/GetTotalFoeScore/"+ ConstantComponent.subscriberIdLogged +"?UserId="+ ConstantComponent.userIdLogged +"&UserSessionId="+ConstantComponent.UserSessionId+"&FormId=0&IpAddress="+ConstantComponent.IpAddress+"&BrowserName="+ConstantComponent.BrowserName+"&BrowserVersion="+ConstantComponent.BrowserVersion).subscribe(data => {            
            this.totalFOE=data
        },
        (error: AppError) => {
            throw error;
        });

        //Called api to get total fleet score details
        this.service.get(ConstantComponent.url + "/KpiMasterAPI/GetTotalFleetScore/"+ ConstantComponent.subscriberIdLogged +"?UserId="+ ConstantComponent.userIdLogged +"&UserSessionId="+ConstantComponent.UserSessionId+"&FormId=0&IpAddress="+ConstantComponent.IpAddress+"&BrowserName="+ConstantComponent.BrowserName+"&BrowserVersion="+ConstantComponent.BrowserVersion).subscribe(data => {                        
            this.totalFleet=data
        },
        (error: AppError) => {
            throw error;
        });
        
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('push-right');
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        
        let headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' });
        let options = new RequestOptions({ headers: headers });
        //Add UserSessionMapping Details in Object
        let UserSessionDetails:any = {};
        UserSessionDetails.UserId = ConstantComponent.userIdLogged;
        UserSessionDetails.UserSessionId = ConstantComponent.UserSessionId;
        UserSessionDetails.FormId = 0;
        UserSessionDetails.IpAddress = ConstantComponent.IpAddress;
        UserSessionDetails.BrowserName = ConstantComponent.BrowserName;
        UserSessionDetails.BrowserVersion = ConstantComponent.BrowserVersion;        

        this.service.update(ConstantComponent.url + '/UserAPI/LogOut/' + parseInt(localStorage.getItem("userIdLogged")), UserSessionDetails).subscribe((data:any) => {                      
        
        if (data.isSuccess == true) {            
            localStorage.clear();
            //     localStorage.removeItem('isLoggedin');
            // localStorage.removeItem('userAllRights');
            // localStorage.removeItem('SuperAdminRights');
            // localStorage.removeItem('subscriberIdLogged');
            // localStorage.removeItem('usernameLogged');
            // localStorage.removeItem('subscriberNameLogged');
            // localStorage.removeItem('userIdLogged');
            // localStorage.removeItem('financialYearIdLogged');
            // localStorage.removeItem('userRights');
            // localStorage.removeItem('MaintainerRights');
            // localStorage.removeItem('tyreDashboardStatus');
            // localStorage.removeItem('companyName');
            // localStorage.removeItem('token');
            // localStorage.removeItem('tokenExp');
            // localStorage.removeItem('userAccessRights'); // remove user access rights
            // localStorage.removeItem('IsLoginUserSubscriber'); // remove check is login  user subscriber or not
            // //Remove User Session Mapping Details from Local Storage
            // localStorage.removeItem('UserSessionId');
            // localStorage.removeItem('IpAddress');
            // localStorage.removeItem('BrowserName');
            // localStorage.removeItem('BrowserVersion');
            // localStorage.removeItem('ModuleIdClick');// for Dynamic Menu
            // localStorage.removeItem('DynamicMenuList');// for dynamic Menu
            // localStorage.removeItem('UserRoleName');
            // localStorage.removeItem('UserRoleId');
            // localStorage.removeItem('IsTrolleyApplicable');// remove Is Trolley Applicable flag
            // localStorage.removeItem('IsCeatCustomer');// remove Is Ceat Customer flag
        }         
        },
        error => {
            // bootbox.alert("Something went wrong! Please try again")
        })
    }

    showHelplineModal = function (content) {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    showDemoModal = function (content, item) {
        this.DataUrl=null;
      this.modalService.open(content, { size: 'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });        
    }

    showZoomImageModal = function (zoomimgecontent, item) {
        window.scrollTo(0,0);
        this.modalService.open(zoomimgecontent, { size: 'lg' }).result.then((result) => {      
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });               
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    os_dropdown = function() {
        $('.os-dropdown-trigger').on('mouseenter', function () {
            $(this).addClass('over');
        });
        $('.os-dropdown-trigger').on('mouseleave', function () {
            $(this).removeClass('over');
        });
    }

    convertTime = function(timeString:string) {
        var nums = timeString.split(':');
        var time
        if(parseInt(nums[0]) > 12) {
            time = (parseInt(nums[0]) - 12) + ':' + nums[1] + "PM" ;
        } else if(parseInt(nums[0]) == 12) {
            time = parseInt(nums[0]) + ':' + nums[1] + "PM" ;
        } else {
            time = parseInt(nums[0]) + ':' + nums[1] + "AM" ;
        }
        return time;
    }

    setDate = function (inputDate) {
        if(inputDate == "") {
            var dtToday = new Date();
        } else {
            var dtToday = new Date(inputDate);
        }
        var month = (dtToday.getMonth() + 1).toString();
        var day = dtToday.getDate().toString();
        var year = dtToday.getFullYear();

        if (dtToday.getMonth() < 9) {
            month = '0' + month.toString();
        }
        if (dtToday.getDate() < 10) {
            day = '0' + day.toString();
        }
        var maxDate = year + '-' + month + '-' + day;
        return maxDate;
    }

    GetPageGuide(){
        let ObjectURL = location.pathname;
        let module = '';
        if(localStorage.getItem('tyreDashboardStatus') == 'true'){
            module = 'tyreDashboard';
        }
        else if(localStorage.getItem('vehicleDashboardStatus') == 'true'){
            module = 'vehicleDashboard';
        }
        else {
            module = 'inventoryDashboard';
        }
        this.service.get(ConstantComponent.url + '/DocumentManagerAPI/GetPageGuide?ObjectURL='+ObjectURL + '&module=' + module)
           .subscribe(data => { 
               let objectetail :any = data; 
               if(objectetail != null && objectetail.fileName != '' && objectetail.fileName.includes('pdf')){
                    // let Image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + objectetail[0].prototypeReportUrl); 
                    // console.log('image', Image)
                    // window.open(objectetail[0].prototypeReportUrl, '_blank');

                    const linkSource = `data:application/pdf;base64,${objectetail.prototypeReportUrl}`;
                    const downloadLink = document.createElement("a");
                    const fileName = objectetail.fileName;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();

                }
            });
    }

    //Get User Profile Image
    GetUserProfileImage = function(){         
         this.service.get(ConstantComponent.url + "/UserAPI/GetUploadProfileImageForUser?userId="+ConstantComponent.userIdLogged).subscribe(data => {            
            this.ImageBase64 = null;
            if(data.length > 0)
            {
                this.imagePath=data[0].documentPath

                this.service.get(ConstantComponent.url + "/FileUpload/?DocumentPath=" + this.imagePath)
                .subscribe(
                data => {
                    console.log(data);
                    this.Base64Data = data;
                    this.ImageData = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + data);                    
                    this.ImageBase64 = this.ImageData.changingThisBreaksApplicationSecurity;
                });
            }
            
        },
        (error: AppError) => {
            throw error;
        });
    }
    
}
